import React, { useEffect, useMemo } from 'react';
import ReactDOM18 from '../utils/ReactMigrator';
import PropTypes from 'prop-types';

import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { fileResourceId } from './file';
import fullScreen from '../base/fullscreen';
import '../view/action-toolbar.vm';
import { sentAnalyticsEvent } from '../base/analytics';
import pubsub from '../base/pubsub';

import FileViewInner from './FileViewInner';
import AppStore from './app-store';
import { Asset } from '../asset/asset';

const FileView = observer((props) => {
    const ctx = useMemo(() => ({}), []);
    const handleFullscreenChange = () => {
        runInAction(() => {
            if (!fullScreen.isFullscreen) {
                props.store.ui.fullScreen = false;
            }
        });
    };

    const sendGA = event => {
        const action = event.target.getAttribute('ga-action');
        const label = event.target.getAttribute('ga-label');
        action && label && sentAnalyticsEvent({ action, label }, { event });
    };

    useEffect(() => {
        pubsub.subscribe(ctx, 'asset.action.delete', () => {
            if (props.store.ui.visible) {
                props.store.ui.hide();
                window.FilePreview.close();
            }
        });
        pubsub.subscribe(ctx, 'asset.action.manageLinks', (returnedAssets) => {
            const oldAsset = props.store.file.file;
            const updatedAsset = returnedAssets.find(a => a.resourceId === oldAsset.resourceId);
            if (updatedAsset) {
                props.store.file.updateFile(Asset.update(oldAsset, (data, options) => {
                    options.sharingInfo = updatedAsset.sharingInfo;
                }));
                props.store.endLoading();
            }
        });
        return () => {
            $(document).off('webkitfullscreenchange mozfullscreenchange fullscreenchange');
            fullScreen.off('change', handleFullscreenChange);
            $(document).off('click', sendGA);
            pubsub.unsubscribe(ctx, 'asset.action.delete');
            pubsub.unsubscribe(ctx, 'asset.action.manageLinks');
        };
    }, []);

    return props.store.ui.visible && (
        Settings.openedFromPublicLink
            ? <FileViewInner store={props.store} />
            : <div className='fv-overlay'>
                <FileViewInner store={props.store} />
            </div>
    );
});

FileView.propTypes = {
    store: PropTypes.object
};

window.FilePreview = (() => {
    let loaded = false;
    const appStore = new AppStore();

    const load = () => {
        if (!loaded) {
            ReactDOM18.render(
                <FileView store={appStore} />,
                document.getElementById('file-preview-app')
            );
            loaded = true;
        }
    };

    return {
        open (filePreviewInterface, fileResource, group = null, options = {}) {
            this.filePreviewInterface = filePreviewInterface;
            load();
            this.onBackListener = this.onBack.bind(this);
            window.addEventListener('popstate', this.onBackListener);
            const url = fileResource.asUrl();
            if (window.location.href !== url) {
                window.history.pushState(null, fileResource.name, url);
            }
            if (group) {
                appStore.file.group = group;
            }
            this.configure(options);
            return appStore.file.open(fileResource);
        },
        close (self, event, fromBack) {
            appStore.file.cleanGroupAndHide();
            appStore.file.clearFile();
            this.removeConfigOptions(appStore.file.fileResource.options);
            window.removeEventListener('popstate', this.onBackListener);
            if (this.filePreviewInterface.opendFromUrl) {
                window.history.replaceState(null, '', appStore.file.fileResource.removeFromUrl());
            } else {
                !fromBack && window.history.go(-1);
            }
            this.filePreviewInterface.close();
        },
        load () {
            return appStore.file.load();
        },
        reload (asset) {
            appStore.file.fileResource = fileResourceId.fromAsset(asset);
            return this.load()
                .then(file => appStore.file.group.cache(file));
        },
        onBack (event) {
            event.preventDefault();
            this.close(this, event, true);
        },
        configure (options) {
            appStore.ui.configure(options);
        },
        removeConfigOptions (options) {
            const newOptions = Object.keys(appStore.ui.options).reduce((acc, k) => {
                if (!options.hasOwnProperty(k)) {
                    acc[k] = appStore.ui.options[k];
                }
                return acc;
            }, {});
            runInAction(() => {
                appStore.ui.options = newOptions;
            });
        }
    };
})();

import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

class Migrator {
    constructor () {
        this.rootsCache = new Map();
    }

    render (reactComponent, rootDOMNode) {
        if (this.rootsCache.get(rootDOMNode)) {
            console.warn('ReactDOM tried to render onto a root that already rendered');
        }
        const root = this.rootsCache.get(rootDOMNode) || createRoot(rootDOMNode);
        this.rootsCache.set(rootDOMNode, root);
        return flushSync(() => root.render(reactComponent));
    }

    unmountComponentAtNode (rootDOMNode) {
        const root = this.rootsCache.get(rootDOMNode);
        if (root) {
            root.unmount();
            this.rootsCache.delete(rootDOMNode);
        }
    }
}

const ReactDOM18 = new Migrator();
export default ReactDOM18;

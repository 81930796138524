import sso from './sso';

import React from 'react';
import ReactDOM18 from '../utils/ReactMigrator';

import WelcomeTour from '../WelcomeTour/WelcomeTour';
import { isLinkView } from '../preview/link';

function inFileBrowser () {
    return /\/portal\/files.*/.test(window.location.pathname) ||
        /\/presentation.*/.test(window.location.pathname) ||
        /\/task.*/.test(window.location.pathname);
}

function inHelp () {
    return /\/portal\/help.*/.test(window.location.pathname);
}

class GlobalContextViewModel {
    constructor () {
        this.context = {
            inFileBrowser: ko.observable(inFileBrowser()),
            inHelp: ko.observable(inHelp()),
            inLinkView: ko.observable(isLinkView()),
            browserPage: ko.observable({
                is: () => false
            })
        };
        this.user = {
            isAuthenticated: Settings.user.isAuthenticated,
            login: Settings.user.login,
            email: Settings.user.email,
            username: Settings.user.username,
            fullName: Settings.user.fullName,
            logout: sso.logout,
            manageAccountUrl: Settings.ACCOUNT_URL,
            showPrivacyLink: Settings.user.showPrivacyLink
        };
        const distributorInfo = Settings.distributor_info;
        this.distributor = {
            name: distributorInfo.name,
            code: distributorInfo.code,
            id: distributorInfo.id,
            imgSrc: distributorInfo.logo,
            contactInfo: distributorInfo.contact_info || ''
        };

        Settings.user.welcomeTour === 'True' && this.showTour();
    }

    set (name, value) {
        if (ko.isObservable(this.context[name])) {
            this.context[name](value);
        } else {
            this.context[name] = value;
        }
    }

    onPage (prefix) {
        return window.location.pathname.startsWith(prefix);
    }

    login (_, event) {
        event.preventDefault();
        const { pathname, search, hash } = window.location;
        sso.login(window.encodeURIComponent(pathname + search + hash));
        return true;
    }

    showTour () {
        const welcomeTourRoot = document.querySelector('#welcome-tour');
        if (welcomeTourRoot) {
            ReactDOM18.render(
                <WelcomeTour />,
                document.querySelector('#welcome-tour')
            );
        }
    }
}

const context = new GlobalContextViewModel();

!ko.components.isRegistered('global-context') &&
ko.components.register('global-context', {
    viewModel: { instance: context },
    template: '<!-- ko template: { nodes: $componentTemplateNodes } --><!-- /ko -->'
});

export default context;

import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import CommentField from './CommentField';
import ResourceDoesNotExist from './ResourceDoesNotExist';

import utils from './utils';
import { toast } from '../toast';
import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';
import Comment from './Comment';
import VCDOCCommentsStore from '../preview/Sections/Comments/vcdoc-store';

class PostCommentField extends React.Component {
    submit = () => {
        if (this.commentField.isProcessing() ||
            this.commentField.hasError() ||
            this.commentField.isEmpty) return Promise.reject();

        const commentsStore = this.props.commentsStore;

        const mentions = utils.getMentions(this.commentField.getContent());

        return commentsStore
            .checkMentionPermissionsAndAlert(mentions)
            .then(() => {
                this.commentField.setProcessing(true);

                let metadata;
                if (commentsStore instanceof VCDOCCommentsStore) {
                    metadata = {
                        vcdoc: {
                            pageNumber: commentsStore.visiblePages[0].pageNumber
                        }
                    };

                    const commentGeometry = commentsStore.tempCommentGeometry;
                    if (commentGeometry) {
                        metadata.vcdoc.geometry = {
                            ...commentGeometry,
                            geometry: commentGeometry.geometry.data
                        };
                    }
                }

                commentsStore.api
                    .submitComment(
                        commentsStore.resource,
                        this.commentField.getContent(),
                        mentions,
                        metadata
                    )
                    .then(comment => {
                        this.commentField.clear();
                        const c = commentsStore.add(comment);
                        c && commentsStore.setFocusedComment(c);
                        this.commentField.setProcessing(false);
                    })
                    .catch(err => {
                        toast('ERROR_OCCURED');
                        this.commentField.setProcessing(false);
                    })
                    .finally(() => {
                        if (commentsStore instanceof VCDOCCommentsStore) {
                            commentsStore.setTempCommentGeometry(undefined);
                        }
                    });
            });
    };

    clearComment = () => {
        this.commentField.clear();
        if (this.props.commentsStore instanceof VCDOCCommentsStore) {
            this.props.commentsStore.setTempCommentGeometry(undefined);
        }
    };

    render () {
        return this.props.commentsStore.resource.exists
            ? <Comment
                comment={{
                    owner: Settings.user
                }}
                id='post-comment-field'
                data-what='post-comment-field'
                style={{ position: 'relative' }}
                active={this.props.commentsStore.tempCommentGeometry}
            >
                <CommentField
                    ref={commentField => { this.commentField = commentField; }}
                    placeholder={gettext('Comment or @mention someone')}
                    onEnter={this.submit}
                    onEscape={this.clearComment}
                    commentsStore={this.props.commentsStore}
                    showButtonsPredicate={isEmpty => {
                        return this.props.commentsStore.tempCommentGeometry
                            ? true
                            : !isEmpty;
                    }}
                >
                    <a
                        className='comment-action-btn'
                        onClick={this.clearComment}
                        ga-action={this.props.commentsStore.GASettings.action}
                        ga-label='Cancel_New_Comment'
                        data-what='comment-action'
                        data-which='comment-cancel'
                    >{gettext('Cancel')}</a>
                    <Button
                        disabled={this.commentField?.hasError()}
                        onClick={this.submit}
                        ga-action={this.props.commentsStore.GASettings.action}
                        ga-label='New_Comment'
                        data-what='comment-action'
                        data-which='comment-post'
                        style={{ lineHeight: '24px', marginLeft: 8 }}
                    >{ gettext('Post') }</Button>

                </CommentField>
            </Comment>
            : <ResourceDoesNotExist />;
    }
}

export default observer(PostCommentField);

PostCommentField.propTypes = {
    commentsStore: PropTypes.object.isRequired
};

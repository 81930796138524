import { action, observable, computed, makeObservable } from 'mobx';
import { Comment } from './comment-classes';

class BaseCommentsStore {
    loading;
    visible;

    comments;
    filters;
    focusedComment;

    get filteredComments () {
        return this.comments
            .filter((c) => (this.filters.resolved ? !c.resolved : true))
            .filter((c) =>
                this.filters.latestVersionOnly
                    ? c.resource.object.version_id ===
                      this.root.currentVersion.versionId
                    : true
            )
            .sort((a, b) => (a.id < b.id ? 1 : -1));
    }

    get canComment () {
        return Settings.user.isAuthenticated;
    }

    constructor (root) {
        makeObservable(this, {
            loading: observable,
            visible: observable,
            comments: observable,
            filters: observable,
            focusedComment: observable,
            setFocusedComment: action,
            filteredComments: computed,
            canComment: computed,

            // Comments
            add: action,
            removeComment: action,
            replaceComment: action,

            // Replies
            addReply: action,
            updateReply: action,
            deleteReply: action,

            setFilters: action
        });

        this.root = root;
        this.comments = [];
        this.filters = {
            resolved: true
        };
    }

    create = (data) => {
        return new Comment(data, this.getComment(data.parent));
    };

    /*
    Assert that the notification is about the currenly previewed resource.
    Otherwise, you will render all the new comments, even if you are previewing
    a different resource (file/slide).
    */
    assertResource = data => {
        return data.resource_type === 'file'
            ? this.root.currentVersion.versionId === data.resource.object.version_id
            : true; // TODO: Implement
    };

    add = (data) => {
        if (!this.assertResource(data)) return;
        if (!this.getComment(data.id)) {
            const c = this.create(data);
            this.comments = [...this.comments, c];
            return c;
        }
    };

    removeComment = (id) => {
        this.comments = this.comments.filter((c) => c.id !== id);
    };

    replaceComment = (newComment) => {
        this.removeComment(newComment.id);
        this.add(newComment);
    };

    addReply = (data) => {
        const parent = this.getComment(data.parent);
        if (!parent) return;
        if (!this.assertResource(parent.data)) return;
        if (this.replyExists(data.id)) {
            return this.updateReply(data);
        };

        parent.replies = parent.replies.concat(this.create(data));
    };

    updateReply = (data) => {
        const parent = this.getComment(data.parent);
        if (!parent) return;
        parent.replies = parent.replies.map(r => r.id === data.id ? this.create(data) : r);
    };

    deleteReply = (data) => {
        const parent = this.getComment(data.parent);
        if (!parent) return;
        parent.replies = parent.replies.filter(r => r.id !== data.id);
    };

    getComment = (id) => {
        return this.comments.find((c) => c.id === id);
    };

    replyExists = (id) => {
        return this.comments.find(c => c.replies.find(r => r.id === id));
    };

    setFilters = (overrides = {}) => {
        this.filters = { ...this.filters, ...overrides };
    };

    setFocusedComment (c) {
        this.focusedComment = c;
    }
}

export default BaseCommentsStore;

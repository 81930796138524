import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { autorun } from 'mobx';

import { Button } from '@vectorworks/vcs-ui/dist/lib/Buttons/Button';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';

import utils from './utils';
import { toast } from '../toast';

import CommentEdit from './CommentEdit';
import CommentField from './CommentField';
import RepliesList from './RepliesList';
import Comment from './Comment';
import CommentPreview from './CommentPreview';
import { observer } from 'mobx-react';
import { S } from './S';

const ExpandedComment = ({ comment, commentsStore }) => {
    const [edit, setEdit] = useState(false);
    const commentField = useRef();
    const ref = useRef();
    comment.ref = ref;

    useEffect(() => {
        const disposer = autorun(() => {
            if (commentsStore.focusedComment === comment) {
                ref.current?.scrollIntoView?.({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        });

        return disposer;
    }, []);

    const toggleResolve = () => {
        if (commentField.current?.isProcessing()) return Promise.reject();

        commentField.current?.setProcessing(true);

        const updatedComment = {
            id: comment.id,
            resolved: !comment.resolved
        };

        commentsStore.api
            .updateComment(commentsStore.resource, updatedComment)
            .then(updated => {
                commentField && commentField.current?.setProcessing(false);
                commentsStore.replaceComment(updated);
            })
            .catch(err => {
                commentField && commentField.current?.setProcessing(false);
                toast('ERROR_OCCURED');
            });
    };

    const deleteComment = () => {
        if (commentField.current?.isProcessing() || comment.replies.length !== 0) {
            return Promise.reject();
        }

        commentsStore.confirmDelete()
            .then(() => {
                commentField.current?.setProcessing(true);

                commentsStore.api
                    .deleteComment(
                        commentsStore.resource,
                        comment.id
                    )
                    .then(res => commentsStore.removeComment(comment.id))
                    .catch(err => toast('ERROR_OCCURED'));
            });
    };

    const reply = () => {
        if (commentField.current?.isProcessing() ||
            commentField.current?.hasError() ||
            commentField.current?.isEmpty) return Promise.reject();

        commentField.current?.setProcessing(true);

        const reply = {
            parent: comment.id,
            content: commentField.current?.getContent()
        };

        const mentions = utils.getMentions(reply.content);

        return commentsStore.checkMentionPermissionsAndAlert(mentions)
            .then(() => {
                commentsStore.api
                    .submitReply(commentsStore.resource, reply, mentions)
                    .then(res => {
                        const newComment = comment.toRaw();
                        newComment.replies.push(res);
                        commentField && commentField.current?.setProcessing(false);
                        commentField.current?.clear();
                        if (comment.resolved) {
                            const updatedComment = {
                                id: comment.id,
                                resolved: !comment.resolved
                            };

                            commentsStore.api
                                .updateComment(
                                    commentsStore.resource,
                                    updatedComment
                                )
                                .then(() => {
                                    newComment.resolved = false;
                                    commentsStore.replaceComment(newComment);
                                });
                        } else {
                            commentsStore.replaceComment(newComment);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        toast('ERROR_OCCURED');
                        commentField && commentField.current?.setProcessing(false);
                    });
            });
    };

    const clearComment = () => commentField.current?.clear();

    const onEdit = () => {
        setEdit(true);
    };

    const onDelete = () => {
        deleteComment();
    };

    return (
        <S.Wrapper
            ref={ref}
            active={(
                commentsStore.focusedComment?.id === comment.id &&
                !!comment.metadata?.vcdoc?.geometry
            )}
            onClick={() => commentsStore.setFocusedComment(comment)}
        >
            <Comment
                key={comment.id}
                comment={comment}
                menuItems={[
                    ...(
                        (
                            comment.owner.login === Settings.user.login &&
                            comment.replies.length === 0 &&
                            utils.checkUpdatePermission(comment.data.pub_date)
                        )
                            ? [
                                <IconButton
                                    key='edit-comment'
                                    onClick={onEdit}
                                    title={gettext('Edit')}
                                    ga-action={commentsStore.GASettings.action}
                                    ga-label='Edit_Comment'
                                    data-what='comment-action'
                                    data-which='edit'
                                >
                                    <Icon icon='edit-16' size='16px' />
                                </IconButton>,
                                <IconButton
                                    key='delete-comment'
                                    onClick={onDelete}
                                    title={gettext('Delete')}
                                    ga-action={commentsStore.GASettings.action}
                                    ga-label='Delete_Comment'
                                    data-what='comment-action'
                                    data-which='delete'
                                >
                                    <Icon icon='trash-16' size='16px' />
                                </IconButton>
                            ]
                            : []
                    )
                ]}
                extraActions={
                    Settings.user.isAuthenticated
                        ? <IconButton
                            toggled={comment.resolved}
                            data-what='comment-action'
                            data-which={comment.resolved ? 'unresolve' : 'resolve'}
                            onClick={e => {
                                e.stopPropagation();
                                toggleResolve();
                            }}
                        >
                            <Icon icon='status-ok-16' size='16px' />
                        </IconButton>
                        : null
                }
            >

                {
                    edit
                        ? <CommentEdit
                            comment={comment}
                            commentsStore={commentsStore}
                            onCancel={() => setEdit(false)}
                            onUpdate={(updated, mentions) => {
                                return commentsStore.api
                                    .updateComment(
                                        commentsStore.resource,
                                        updated,
                                        mentions
                                    )
                                    .then(res => {
                                        commentsStore.replaceComment(res);
                                    })
                                    .catch(err => {
                                        this.editField.setProcessing(false);
                                        toast('ERROR_OCCURED');
                                    })
                                    .finally(() => setEdit(false));
                            }}
                        />
                        : <CommentPreview
                            comment={comment}
                        />
                }
            </Comment>

            <RepliesList
                comment={comment}
                commentsStore={commentsStore}
            >
                {
                    commentsStore.canComment &&
                    commentsStore.focusedComment === comment &&
                    <Comment
                        comment={{
                            owner: Settings.user
                        }}
                        id='post-reply-field'
                        data-what='post-reply-field'
                    >

                        <CommentField
                            ref={commentField}
                            onEnter={reply}
                            onEscape={clearComment}
                            comment={comment}
                            placeholder={
                                commentsStore.resource.exists
                                    ? comment.resolved
                                        ? gettext('Replying will re-open this issue.')
                                        : commentsStore.canComment
                                            ? gettext('Reply...')
                                            : gettext('Please sign in to post replies.')
                                    : gettext('Restore the resource to be able to post replies.')
                            }
                            commentsStore={commentsStore}
                        >
                            <a
                                className='comment-action-btn'
                                onClick={clearComment}
                                ga-action={commentsStore.GASettings.action}
                                ga-label='Cancel_Comment_Reply'
                                data-what='comment-action'
                                data-which='cancel-reply'
                            >{gettext('Cancel')}</a>
                            <Button
                                onClick={!commentField.current?.hasError() ? reply : null}
                                ga-action={commentsStore.GASettings.action}
                                ga-label='New_Comment_Reply'
                                data-what='comment-action'
                                data-which='reply'
                                disabled={commentField.current?.hasError()}
                                style={{ lineHeight: '24px', marginLeft: 8 }}
                            >{gettext('Reply')}</Button>
                        </CommentField>
                    </Comment>
                }
            </RepliesList>
        </S.Wrapper>
    );
};

export default observer(ExpandedComment);

ExpandedComment.propTypes = {
    comment: PropTypes.object,
    commentsStore: PropTypes.object.isRequired
};

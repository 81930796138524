import ReactDOM18 from '../utils/ReactMigrator';
import React from 'react';

import { DialogContainer, DialogStore } from '@vectorworks/vcs-ui/dist/lib/Dialog';

window.ReactDialogs = (() => {
    let loaded = false;
    const store = new DialogStore();

    const load = () => {
        if (!loaded) {
            ReactDOM18.render(
                <DialogContainer dialogContainer={store} />,
                document.getElementById('dialogs-react')
            );
            loaded = true;
        }
    };

    return {
        open (params) {
            load();
            return store.open(params);
        },
        pop () {
            return store.pop();
        },
        dismissAll (reason) {
            return store.dismissAll(reason);
        }
    };
})();

import { computed, autorun, action, runInAction, makeObservable } from 'mobx';
import pubsub from '../../../base/pubsub';
import api from './api';
import { Dialog } from '../../../base/dialog';
import utils from '../../../comments/utils';
import { Comment } from '../../../comments/comment-classes';

import BaseCommentsStore from '../../../comments/store';

class CommentsStore extends BaseCommentsStore {
    constructor (root) {
        super(root);

        makeObservable(this, {
            resource: computed,
            resourceComments: computed,
            withAccess: computed,
            load: action,
            bindClearReaction: action,
            bindUIStoreReaction: action,
            bindRegisterNotificationListener: action,
            bindReactions: action,
            allowComments: computed
        });

        this.api = api;
        this.GASettings = { action: 'Comments_File_View' };
        this.setFilters({ latestVersionOnly: true });
        this.disposers = [];
    };

    get allowComments () {
        const allowComments = this.resource.sharingInfo && this.resource.sharingInfo.allowComments;
        const isOpenedFromLink = this.root.fileResource.isLink;
        return isOpenedFromLink && allowComments || !isOpenedFromLink;
    }

    get resource () {
        return this.root.file;
    }

    get resourceComments () {
        return this.comments;
    }

    get withAccess () {
        return this.resource.sharingInfo.sharedWith.map(u => {
            return {
                login: u.login,
                name: u.username,
                email: u.email
            };
        });
    }

    bindPubsubHandlers () {
        const subscriptions = [
            pubsub.subscribe(this, 'files.comments.comment.create', comment => this.add(comment)),
            pubsub.subscribe(this, 'files.comments.comment.update', comment => this.replaceComment(comment)),
            pubsub.subscribe(this, 'files.comments.comment.delete', comment => this.removeComment(comment.id)),
            pubsub.subscribe(this, 'files.comments.reply.create', reply => this.addReply(reply)),
            pubsub.subscribe(this, 'files.comments.reply.update', reply => this.updateReply(reply)),
            pubsub.subscribe(this, 'files.comments.reply.delete', reply => this.deleteReply(reply))
        ];

        return () => {
            subscriptions.forEach(s => s.dispose());
        };
    }

    bindClearReaction () {
        return autorun(() => {
            if (this.resource.isEmpty) {
                this.comments = [];
            }
        });
    };

    bindUIStoreReaction () {
        return autorun(() => {
            if (this.root.root.ui.comments) {
                this.load(this.resource);
            }
        }, { delay: 100 });
    };

    bindRegisterNotificationListener () {
        return autorun(() => {
            if (this.resource && !this.resource.isEmpty) {
                api.registerNotificationListener(this.resource);
            }
        });
    };

    bindReactions (others = [this.bindUIStoreReaction.bind(this)]) {
        this.disposers = [
            this.bindRegisterNotificationListener(),
            this.bindPubsubHandlers(),
            this.bindClearReaction(),
            ...others.map(f => f())
        ];
    };

    dispose () {
        this.disposers.forEach(d => d());
        this.disposers = [];
    };

    load = _file => {
        if (!this.allowComments) return;
        if (this.resource.isEmpty) return;

        const file = _file || this.resource;
        this.loading = true;

        const ownerInfo = file.ownerInfo;
        const commentsRequest = (() => {
            if (ownerInfo.link) {
                const linkUuid = ownerInfo.link.uuid;
                return api.getCommentsFromShareableLink(file, linkUuid);
            } else {
                return api.getCommentsFromFile(file);
            }
        })();

        return commentsRequest
            .then(comments => {
                runInAction(() => {
                    this.comments = comments.map(comment => new Comment(comment));
                    this.loading = false;
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.comments = [];
                    this.loading = false;
                });
            });
    };

    checkMentionPermissionsAndAlert = mentions => {
        if (!mentions.length) {
            return Promise.resolve();
        }

        return Promise.all(mentions.map(m => api.checkAccess(this.resource, utils.clearEmpty(m))))
            .then(permissions => {
                return this.root.isOwner && !this.root.hasLink && permissions.indexOf('false') > -1
                    ? Dialog.open({
                        component: 'dialog-confirm-mention',
                        ctx: {}
                    }).result
                    : Promise.resolve();
            });
    };

    checkAccess = user =>
        this.api.checkAccess(this.resource, user) ||
        this.root.isOwner ||
        this.root.hasLink;

    confirmDelete = () => {
        return Dialog.open({
            component: 'dialog-delete-comment',
            ctx: {}
        }).result;
    };
}

export default CommentsStore;
